"use strict";

require("slick-carousel");

class Carousel {
	init() {
		$(function () {
			//Slick slider options
			$(".slider").slick({
				arrows: false,
				dots: true,
			});
		});
	}
}

module.exports = new Carousel();
