'use strict';

import {PhoneInput} from "../modules/PhoneInput";
import salutationFormHelper from "../modules/salutationFormHelper";

// extend for usage in templates
window.PhoneInput = PhoneInput;

// PhoneComponent
function bindPhoneInput() {
	$('.snf-phone-input').each(function () {
		new PhoneInput($(this).get(0)).init();
	});
}
$(function () {
	bindPhoneInput();
	$.nette.ext('phoneInput', {
		complete: function () {
			bindPhoneInput();
		},
	});
});

function preventDoubleclicks() {
	$(function () {
		$(".btn-prevent-doubleclick").on("click", function () {
			if (Nette.validateForm(this, false)) {
				$(this).submit();
				this.form && this.form.submit();
				$(this).prop("disabled", true);
				$(this).attr("disabled", true);
			}
		});
		$(".btn-prevent-doubleclick-link").on("click", function () {
			if ($(this).prop("disabled") === true) return false;
			$(this).prop("disabled", true);
			$(this).attr("disabled", true);
		});

		salutationFormHelper($("#frm-profileInfoForm-profile"));
		salutationFormHelper($("#frm-addFormControl-addForm"));
		salutationFormHelper($("#frm-editFormControl-editForm"));
	});
}

$(function () {
	preventDoubleclicks();
	$.nette.ext("preventDoubleclicks", {
		complete: function () {
			preventDoubleclicks();
		},
	});
});
