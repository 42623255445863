'use strict'

const wsLib = require('./websocket.module');

class LiveBidding {
	constructor() {
		this.template = '';  // TYPE, TIME, MESSAGE, ICON gets replaced by the actual value
		this.messagesIcons = {};
		this.onUpdate = undefined;  // custom callback, id as argument
		this.connectionAttemptsTimer = null;
		this.connectionShowFailed = false;
		this.connectionFailed = false;

		this.value = null;
		this.bidValue = null;
		this.bidBtnSelector = null;
		this.bidBtn = null;
		this.biddingOpen = false;
		this.clientId = null;
		this.myBidValue = null;
		this.fairWarning = false;
		this.amWinning = null;
		this.valueString = '';
		this.bidValueString = '';
		this.lotOpen = true;
		this.myBidValueString = '';
	}

	init(config) {
		this.bidBtn = $(this.bidBtnSelector);
		this.bidBtn.prop('disabled', true);
		this.bidBtn.html("Online bidding hasn't opened yet");
		this.connect(config);
	}

	connect(config) {
		if ((this.connectionShowFailed && this.connectionFailed) || (!this.connectionShowFailed && !this.connectionFailed)) {
			this.showNotification('Connecting to the room…', 'info');
		}
		const that = this;
		wsLib.init()
			.then(ws => {
				this.bidWs = ws;
				this.bidWs.onmessage = this.processMessage.bind(this);
				this.bidWs.onclose = function () {
					that.connectionFailed = true;
					setTimeout(function () {
						that.connectionShowFailed = true;
					}, 1000);
					that.connect(config);
				}
				this.startListening(config);
				this.connectionFailed = false;
				this.connectionShowFailed = false;
			})
			.catch(() => {
				if (this.connectionShowFailed || !this.connectionFailed) {
					this.showNotification('Connection failed. Next attempt in 5 seconds…', 'danger');
					setTimeout(function () {
						that.connect(config);
					}, 5000);
				} else {
					that.connect(config);
				}
			})
	}

	send(msg) {
		this.bidWs.send(JSON.stringify(msg));
	}

	startListening({tenant, lotNumber, saleId, clientId, clientName}) {
		this.clientId = clientId
		this.send({
			'method': 'onlineBiddingListener',
			'data': {
				'tenant': tenant,
				'lotNumber': lotNumber,
				'saleId': saleId,
				'clientId': clientId,
				'clientName': clientName
			},
		});
	}

	placeBid() {
		if (this.biddingOpen) {
			this.send({
				'method': 'onlineBid'
			});
			this.bidBtn.prop('disabled', true);
			this.myBidValue = this.bidValue;
			this.bidBtn.html(`Placed ${this.myBidValueString} bid`);
			this.fairWarning = false;
			this.myBidValueString = this.bidValueString;
			this.amWinning = null;
		}
	}

	processMessage(env) {
		// default message, ignoring
		if (env.data === 'hello') {
			return;
		}

		let message;

		try {
			message = JSON.parse(env.data);
		} catch (e) {
			console.error('ws: Invalid Data:', env.data);
			return;
		}

		if (message && message.subscriptionId !== undefined) {
			this.showNotification("You're now connected to the room.", 'info');
			return;
		}

		// update state
		if (message && message.data) {
			this.value = message.data.currentValue !== undefined ? message.data.currentValue : this.value;
			this.bidValue = message.data.currentBidValue !== undefined ? message.data.currentBidValue : this.bidValue;
			this.biddingOpen = message.data.biddingOpened !== undefined ? message.data.biddingOpened : this.biddingOpen;
			this.fairWarning = message.data.fairWarning !== undefined ? message.data.fairWarning : false;
			this.amWinning = message.data.winningClient !== undefined ? message.data.winningClient : false;
			this.valueString = message.data.currentValueString !== undefined ? message.data.currentValueString : this.valueString;
			this.bidValueString = message.data.currentBidValueString !== undefined ? message.data.currentBidValueString : this.bidValueString;
			this.lotOpen = message.data.lotOpen !== undefined ? message.data.lotOpen : true;
		}

		if (message && message.method && (message.method === 'resetLot' || message.method === 'undoBid')) {
			this.myBidValue = null;
			this.myBidValueString = '';
			this.hideMyBid();
		}

		// show message
		if (message && message.message) {
			this.showNotification(
				message.message.content ? message.message.content : message.message,
				message.message.type ? message.message.type : 'info',
				message.message.time ? message.message.time : null
			);
		}

		if (this.value !== null) {
			$('#lot-detail__bid-label').html($('#lot-detail__bid-label').data('message-current'));
			$('#lot-detail__bid').html(this.valueString);
		}

		if (this.amWinning === true) {
			this.myBidValue = this.value;
			this.myBidValueString = this.valueString;
			if (message.method === 'init') {
				this.renderNewBid(true);
			}
		}

		if (this.biddingOpen && this.lotOpen && !(this.myBidValue !== null && ((this.myBidValue === this.value && this.amWinning) || (this.myBidValue === this.bidValue && this.amWinning === null)))) {
			this.bidBtn.prop('disabled', false);
			this.bidBtn.html(`Place ${this.bidValueString} bid`);
		} else {
			this.bidBtn.prop('disabled', true);
			if (this.biddingOpen === false) {
				this.bidBtn.html(`Online bidding hasn't opened yet`);
			} else if (this.myBidValue !== null && ((this.myBidValue === this.value && this.amWinning) || (this.myBidValue === this.bidValue && this.amWinning === null))) {
				this.bidBtn.html(`Placed ${this.myBidValueString} bid`);
			} else {
				this.bidBtn.html(`Can't place a bid right now`);
			}
		}

		if (this.myBidValueString !== '') {
			this.showMyBid(this.myBidValueString);
		}

		if (message && message.method && message.method === 'newProductBid') {
			this.renderNewBid(this.amWinning);
			return;
		}
		if (message && message.method && message.method === 'lotClosedEab') {
			setTimeout(function () {
				location.reload()
			}, 5000);
			return;
		}
		if (message && message.method && message.method === 'lotSold') {
			setTimeout(function () {
				location.reload()
			}, 5000);
			return;
		}
		if (message && message.method && message.method === 'lotUnsold') {
			setTimeout(function () {
				location.reload()
			}, 5000);
			return;
		}

	}

	renderNewBid(winningClient) {
		if (winningClient) {
			this.showNotification(`You're winning! Your ${this.myBidValueString} bid has been accepted.`, 'success');
		} else {
			if (this.myBidValue !== null && this.myBidValue === this.value) {
				this.showNotification(`You're not the highest bidder. Your ${this.myBidValueString} bid has not been accepted.`, 'danger');
			} else {
				this.showNotification(`New ${this.valueString} bid has been placed by the auctioneer.`, 'info');
			}
		}
	}

	showNotification(message, type, timeObject) {
		if (typeof timeObject === 'number' || typeof timeObject === 'string') {
			timeObject = new Date(timeObject);
		}

		if (timeObject === undefined || timeObject === null) {
			timeObject = new Date();
		}

		$('#live-bidding__notifications table td').each(function () {
			$(this).removeClass('messages__message-content--bold');
		})

		$('#live-bidding__notifications table')
			.prepend(
				this.template
					.replace('TIME', `${timeObject.toLocaleTimeString()}`)
					.replace('MESSAGE', `${message}`)
					.replace('TYPE', type)
					.replace('ICON', this.messagesIcons[type])
					.replace('CLASSES', ' messages__message-content--bold')
			);
	}

	showMyBid(valueString) {
		$('#lot-detail__my-live-bid').html(valueString);
		$('#lot-detail__my-live-bid').parent().show();
	}

	hideMyBid() {
		$('#lot-detail__my-live-bid').parent().hide();
	}
}

module.exports = new LiveBidding();
