'use strict';

import 'intl-tel-input';
import Cookies from 'js-cookie';

export class PhoneInput {
	constructor(el) {
		this.el = el;
	}

	init() {
		const self = this;
		const cookieProperty = 'intlTelInput-country';

		let name = this.el.getAttribute('name');
		let telInput = $(this.el);
		let countryCode = Cookies.get(cookieProperty);

		telInput.intlTelInput({
			autoPlaceholder: 'aggressive',
			utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/13.0.4/js/utils.js',
			autoHideDialCode: false,
			formatOnDisplay: true,
			hiddenInput: 'fullPhone[' + name + ']',
			initialCountry: 'auto',
			separateDialCode: true,
			geoIpLookup: function (callback) {
				if (countryCode) {
					return callback(countryCode)
				}

				$.get("https://ipinfo.io", function () {
				}, "jsonp").always(function (resp) {
					let countryCode = (resp && resp.country) ? resp.country : "";
					Cookies.set(cookieProperty, countryCode);
					callback(countryCode);
				});
			},
		});

		// hiddenInput option needs validation
		telInput.blur(function () {
			let msg = 'Invalid phone number';
			if (telInput.closest('div.input-group').length) {
				self.validate(telInput, 'div.input-group', 'error', '.error-message', `<div class="error-message">${msg}</div>`);
			} else if (telInput.closest('div.form-group').length) {
				self.validate(telInput, 'div.form-group', 'error', '.error-message', `<div class="error-message">${msg}</div>`);
			} else {
				self.validate(telInput, 'div.app-frm-cnt', 'has-error', '.help-block', `<span class="help-block"><i class="fa fa-times"></i> ${msg}</span>`);
			}
		});
	}

	validate(telInput, parent, eClass, eBlock, inner) {
		let er = telInput.closest(parent);
		er.removeClass(eClass);
		er.find(eBlock).remove();

		if ($.trim(telInput.val()) && !telInput.intlTelInput("isValidNumber")) {
			er.append(inner);
			er.addClass(eClass);
		}
	}
}
