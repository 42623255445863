'use strict';

class Languages {
	init() {
		let selects = $('.panel-languages select');

		// Load language
		let lang = location.pathname.slice(1, 3);
		selects.val(lang);

		// Change
		selects.change(function () {
			// Sync
			selects.val(this.value);
			// Location
			window.location = '/' + this.value + location.pathname.slice(3);
		});
	}
}

let instance = new Languages();
module.exports = instance;
