'use strict';
// noinspection ES6UnusedImports -> is necessary for "$spinner.spritespin({..." to work
import spritespin from 'spritespin';

class ItemDetailPhoto {
	init() {
		//Disabled contextmenu in lot pages
		$('.lot, .zoomLens').on('contextmenu', function (e) {
			e.preventDefault();
		});

		let self = this;
		let $spinner = $('#instrumentSpinner');
		let isVisible = $spinner.not('.d-none');

		if ($spinner.length && isVisible) {
			self.run($spinner);
		}
	}

	run($spinner) {
		let $spinRange = $('#spinRange');
		let $zoomFull = $('#zoomFullScreen');
		let $zoomFit = $('#zoomFitToScreen');
		let photos = $spinner.data('photos');
		let api;

		$spinner.spritespin({
			source: photos['thumbs'],
			width: 350,
			height: 583,
			frame: 0,
			animate: false,
			plugins: [
				'progress',
				'360',
				'drag'
			],
			onFrameChanged: function () {
				$spinRange.val(api.currentFrame());
				$zoomFull.attr('href', photos['zooms'][api.currentFrame()]);
				$zoomFit.attr('href', photos['zooms'][api.currentFrame()]);
			}
		});

		api = $spinner.spritespin('api');

		$spinRange.on('input', function () {
			let $this = $(this);
			api.updateFrame($this.val());

			if (api.data.loop = true) {
				$this.val(api.currentFrame());
			}
		});

		$('#togglePlaySpinner').on('click', function () {
			api.data.loop = true;
			api.data.frameTime = 60;
			api.toggleAnimation();
			$(this).children().toggleClass('fa-play fa-stop');
		});

		$('#prevFrame').on('click', function () {
			api.prevFrame();
		});

		$('#nextFrame').on('click', function () {
			api.nextFrame();
		});
	}

	show() {
		$('.spin-photo').removeClass('d-none');
		$('.photo-nav').removeClass('d-none');
		$('.load-360').addClass('d-none');
		$('.single-photo').addClass('d-none');
	}
}

let itemDetailPhoto = new ItemDetailPhoto();
module.exports = itemDetailPhoto;
