'use strict';

class Navigation {
	init() {
		let self = this;

		$(function () {
			$(window).resize(self.onChange);
			$(window).scroll(self.onChange);

			self.onChange();
		});
	}

	onChange() {
		if ($(window).width() < 1200) {
			if ($(window).scrollTop() >= 0) {
				$('header').addClass('navbar navbar_fix');
			} else {
				$('header').removeClass('navbar navbar_fix');
			}
		} else {
			$('header').removeClass('navbar_fix');
			if ($(window).scrollTop() > 100) {
				$('header').addClass('navbar');
			} else {
				$('header').removeClass('navbar');
			}
		}

		if ($(window).scrollTop() > 100) {
			$('header').addClass('navbar_scroll');
		} else {
			$('header').removeClass('navbar_scroll');
		}
	}

}

let instance = new Navigation();
module.exports = instance;
