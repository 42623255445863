<template>
	<div>
		<template v-if="endsIn.asMilliseconds() >= 0">
			<span>
				{{ Math.floor(endsIn.asDays()) }}{{ langParsed.days }}
			</span>
			<span>{{ endsIn.hours() }}{{ langParsed.hours }}</span>
			<span>{{ endsIn.minutes() }}{{ langParsed.minutes }}</span>
			<span>{{ endsIn.seconds() }}{{ langParsed.seconds }}</span>
		</template>
		<template v-if="endsIn.milliseconds() < 0">
			<span>{{ langParsed.ended }}</span>
		</template>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as moment from "moment";

export default defineComponent({
	props: {
		saleId: String,
		lotNumber: String,
		tenantId: String,
		lang: String,
		secondsFromEnd: Number,
		shouldRefreshAtTheEnd: Boolean,
	},
	data() {
		return {
			langParsed: {
				days: "days",
				hours: "hrs",
				minutes: "mins",
				seconds: "seconds",
				ended: "Bidding ended",
			},
			endsIn: moment.duration(),
			endDate: moment.utc(),
			alreadyRefreshed: false,
		};
	},
	methods: {
		uuidv4() {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
				/[xy]/g,
				function (c) {
					const r = (Math.random() * 16) | 0,
						v = c == "x" ? r : (r & 0x3) | 0x8;
					return v.toString(16);
				}
			);
		},
		subscribeToWs() {
			this.$socket.sendObj({
				requestId: this.uuidv4(),
				method: "bidListener",
				data: {
					lotNumber: this.lotNumber,
					saleId: this.saleId,
					tenant: this.tenantId,
				},
			});
		},
		updateDiff() {
			this.endsIn = moment.duration(this.endDate.diff(moment().utc()));
			if (
				this.endsIn.asMilliseconds() <= 0 &&
				this.shouldRefreshAtTheEnd &&
				!this.alreadyRefreshed
			) {
				this.alreadyRefreshed = true;
				window.location.reload();
			} else if (this.endsIn.asMilliseconds() <= 0) {
				document
					.querySelector(
						".catalogue-lot[data-lot-number='" +
							this.lotNumber +
							"']"
					)
					?.remove();

				document
					.querySelector(
						".catalogue-list tr[data-lot-number='" +
							this.lotNumber +
							"']"
					)
					?.remove();
			}
			window.requestAnimationFrame(this.updateDiff);
		},
		setEndDate(secondsFromEnd: number) {
			this.endDate = moment.utc().add(secondsFromEnd, "seconds");
		},
	},
	mounted() {
		this.langParsed = JSON.parse(this.lang || "");
		this.$options.sockets.onopen = () => this.subscribeToWs();
		this.$options.sockets.onmessage = (websocketMessage: {
			data: string;
		}) => {
			const message = JSON.parse(websocketMessage.data);
			if (
				message.method === "newProductBid" &&
				message.lotNumber === this.lotNumber &&
				message.saleId === this.saleId
			) {
				const secondsToEnd = message.data.secondsFromEnd;
				this.setEndDate(secondsToEnd || 0);
			}
		};
		this.setEndDate(this.secondsFromEnd || 0);
		window.requestAnimationFrame(this.updateDiff);
	},
});
</script>

<style scoped>
span {
	margin-right: 1ch;
}
</style>
