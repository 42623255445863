'use strict';

class Forms {
	init() {
		$(function () {
			$('select[data-bidding_target]').change(function () {
				let form = $(this).closest('form');
				let name = $(this).data('bidding_target');
				let target = form.find('select[name="' + name + '"]');

				$.ajax({
					url: $(this).data('bidding_url'),
					data: {selectValue: $(this).val()},
					success: function (payload) {
						target.empty();

						$.each(payload.options, function (index, value) {
							let option = $("<option></option>")
								.attr("value", value)
								.text(value);
							target.append(option);
						});
					}
				})
			});

			$('select[data-redirect]').change(function () {
				let url = $(this).data('redirect');
				url += (url.indexOf('?') === -1 ? '?' : '&');
				url += 'selectValue=' + $(this).val();

				window.location.href = url;
			});
		});
	}
}

let instance = new Forms();
module.exports = instance;
