'use strict';

class CatalogueFilter {
	init() {
		$(document).on('click', '.btn-filters', function () {
			let catalogueFilterHeight = $(".catalogue__filter:not(.catalogue__filter--static)").outerHeight();
			$(".catalogue__filter:not(.catalogue__filter--static)").css({
				top: -catalogueFilterHeight,
			});
			$(".btn-filters__icon").toggleClass('rotate');

			$(".catalogue__filter:not(.catalogue__filter--static)").toggleClass('d-none');
			$(".btn-filters__title").toggleClass('d-none');
		});

		$(window).on('resize', this.changeFilterPosition);

		$(document).on('submit', 'form#frm-filterForm', this.changeFilterPosition);

		if($('.catalogue__filter').hasClass('with-errors')) {
			$('.btn-filters').trigger('click');
		}
	}
	changeFilterPosition() {
		let catalogueFilterHeight = $(".catalogue__filter:not(.catalogue__filter--static)").outerHeight();
		$(".catalogue__filter:not(.catalogue__filter--static)").css({
			top: -catalogueFilterHeight,
		});
	}
}

module.exports = new CatalogueFilter();
