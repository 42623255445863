<template>
	<tr class="highlight">
		<td id="lot-detail__bid-label">{{ isCurrentBid === true ? currentBidStr : startingBidStr }}:</td>
		<td id="lot-detail__bid">
			{{
				formatCurrency(
					lotData.winningBid.amount,
					lotData.winningBid.currency
				)
			}}
		</td>
	</tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { initializeApp } from "firebase/app";
import {
	getFirestore,
	connectFirestoreEmulator,
	doc,
	onSnapshot,
} from "firebase/firestore";
import { environment } from "../config/env_real-time";

const app = initializeApp(environment.firebaseConfig);
const db = getFirestore(app, environment.firestore.databaseId);

if (!environment.production) {
	connectFirestoreEmulator(db, "localhost", 8080);
}

interface LotData {
	endTime: string;
	status: string;
	winningBid: {
		amount: number;
		currency: string;
	};
	winningBidder: string;
}

export default defineComponent({
	props: {
		partnerTicker: {
			type: String,
			required: true,
		},
		auctionShortName: {
			type: String,
			required: true,
		},
		lotNumber: {
			type: String,
			required: true,
		},
		currentValue: {
			type: Number,
			required: true,
		},
		currency: {
			type: String,
			required: true,
		},
		startingBidStr: {
			type: String,
			required: true,
		},
		currentBidStr: {
			type: String,
			required: true,
		},
		hasBid: {
			type: String,
			required: true,
		},
		updateLink: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			lotData: {
				winningBid: {
					amount: this.$props.currentValue,
					currency: this.$props.currency,
				},
			} as LotData | null,
			unsubscribe: null as (() => void) | null,
			isCurrentBid: this.$props.hasBid === 'true' || false,
		};
	},
	watch: {
		partnerTicker: "fetchRealTimeData",
		auctionShortName: "fetchRealTimeData",
		lotNumber: "fetchRealTimeData",
		currentValue: "fetchRealTimeData",
	},
	methods: {
		fetchRealTimeData() {
			if (this.unsubscribe) {
				this.unsubscribe();
			}
			const path = `partners/${this.partnerTicker.toLowerCase()}/auctions/${this.auctionShortName.toLowerCase()}/lots/${this.lotNumber.toLowerCase()}`;
			const docRef = doc(db, path);

			this.unsubscribe = onSnapshot(docRef, (doc) => {
				if (doc.exists()) {
					this.lotData = doc.data() as LotData;
					this.isCurrentBid = true;
					this.updateBidAndTimeAJAX();
				}
			});
		},
		formatCurrency(value: number, currency: string) {
			let formatter = new Intl.NumberFormat("en-GB", {
				style: "currency",
				currency: currency,
				minimumFractionDigits: 0,
			});
			return formatter.format(value);
		},
		updateBidAndTimeAJAX() {
			// @ts-ignore
			if ($.active === 0) {
				// @ts-ignore
				$.nette.ajax({
					type: "GET",
					url: this.updateLink,
				});
			}
		},
	},
	mounted() {
		this.fetchRealTimeData();
	},
	beforeUnmount() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	},
});
</script>
