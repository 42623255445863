<template>
	<div>
		{{ formatDate(endDate.format()) }}
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import * as moment from "moment-timezone";

export default defineComponent({
	props: {
		saleId: String,
		lotNumber: String,
		tenantId: String,
		deadlineTimestamp: Number,
		timeZone: String,
		dateFormat: String,
	},
	data() {
		return {
			endDate: moment.utc(),
		};
	},
	methods: {
		uuidv4() {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
				/[xy]/g,
				function (c) {
					var r = (Math.random() * 16) | 0,
						v = c == "x" ? r : (r & 0x3) | 0x8;
					return v.toString(16);
				}
			);
		},
		subscribeToWs() {
			this.$socket.sendObj({
				requestId: this.uuidv4(),
				method: "bidListener",
				data: {
					lotNumber: this.lotNumber,
					saleId: this.saleId,
					tenant: this.tenantId,
				},
			});
		},
		formatDate(date: string) {
			return moment.utc(date).tz(this.timeZone || 'UTC').format(this.dateFormat);
		},
		setEndDate(timestamp: number) {
			this.endDate = moment.unix(timestamp).utc();
		},
	},
	mounted() {
		this.$options.sockets.onopen = () => this.subscribeToWs();
		this.$options.sockets.onmessage = (websocketMessage: {
			data: string;
		}) => {
			const message = JSON.parse(websocketMessage.data);
			if (
				message.method === "newProductBid" &&
				message.lotNumber === this.lotNumber &&
				message.saleId === this.saleId &&
				message.data.endTimeTimestamp
			) {
				this.setEndDate(message.data.endTimeTimestamp);
			}
		};
		this.setEndDate(this.deadlineTimestamp || 0);
	},
});
</script>

<style scoped></style>
