<template>
	<div>
		<textarea
			:class="`${className || ''} form-control`"
			v-model="message"
			placeholder=""
			:maxlength="maxlength"
			:name="name"
			:rows="rows"
		></textarea>
		<p class="text-right text-small text-danger" v-if="hasError">
			Entered text is too long!
		</p>
		<p class="text-right text-small" v-if="!hasError">
			{{ remainingCount }} / {{ maxlength }}
		</p>
	</div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		maxlength: Number,
		rows: String,
		name: String,
		className: String,
		default: String,
	},
	data() {
		return {
			message: "",
		};
	},
	computed: {
		remainingCount: function () {
			return this.maxlength - this.message.length;
		},
		hasError: function () {
			return this.remainingCount < 0;
		},
	},
	mounted() {
		this.message = this.default;
	}
});
</script>

<style lang="scss" scoped>
textarea.form-control {
	height: 150px;
	margin-bottom: 5px;
}

.text-default {
	font-size: 1em;
}

.text-small {
	font-size: 0.9em;
}

.text-light {
	color: #666;
}
</style>
