// disable submit for ajax request
$.nette.ext('disableSubmit', {
	start: function (jqXhr, settings) {
		if (settings.nette !== undefined) {
			let $el = $(settings.nette.el);

			let btnCancel = $el.parents('div').find('.button--white');

			if (!$el.hasClass('button--ajax-loading')) return;

			$el
				.addClass('button--disabled')
				.prop('disabled', true)
				.append("<span><i class=\"fas fa-spinner fa-spin\"></i></span>")
				.children("div").css({
				display: 'none'
			});
			btnCancel.addClass('button--disabled');
			btnCancel.removeAttr('data-fancybox-close');
			btnCancel.removeAttr('href');
		}
	},
	complete: function (jqXHR, status, settings) {
		if (settings.nette !== undefined) {
			let $el = $(settings.nette.el);
			let btnCancel = $el.parents('div').find('.button--white');

			if (!$el.hasClass('button--ajax-loading')) return;

			$el
				.removeClass('button--disabled')
				.removeAttr('disabled')
				.children("span").remove();
			$el.children("div").removeAttr('style');
			btnCancel.removeClass('disabled');
		}
	},
});
