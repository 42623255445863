'use strict';

//Forms
window.Nette = global.Nette = require('../../../../../vendor/nette/forms/src/assets/netteForms.min.js');

// Ajax
require('nette.ajax.js');

// Dependent select box
require('../../../../../vendor/nasext/dependent-select-box/client-side/dependentSelectBox');

class NetteService {
	init() {
		const self = this;
		$(function () {
			$.nette.init();
			self.activate();
			$.nette.ext('select2', {
				complete: function () {
					self.activate();
				},
			});
		});
	}

	activate() {
		// Init dependent select box (e.g.: country/region selects)
		$('[data-dependentselectbox]').dependentSelectBox();

		// Init Nette Forms
		Nette.initOnLoad();

		// Overwrite Nette Forms methods (customize)

		/**
		 * Instead of just throwing alert() try displaying the error under the effected input. If not possible
		 * show alert().
		 *
		 * @param form
		 * @param errors
		 */
		Nette.showFormErrors = function (form, errors) {
			var messages = [],
				focusElem;

			// Clear errors
			var $form = $(form);
			$form.find('.error').removeClass('error').addClass('success');
			$form.find('.error-message').remove();

			for (var i = 0; i < errors.length; i++) {
				var elem = errors[i].element,
					message = errors[i].message;

				if (messages.indexOf(message) < 0) {
					if (elem.focus) {
						let parent = $(elem).parents(".form-group").length
							? '.form-group'
							: '.input-group';
						$(elem)
							.closest(parent)
							.removeClass('success')
							.addClass('error')
							.append(
								"<div class='error-message'>" +
									message +
									"</div>"
							);
					} else {
						messages.push(message);
					}

					if (!focusElem && elem.focus) {
						focusElem = elem;
					}
				}
			}

			if (focusElem) {
				focusElem.focus();
			}
		};

		/**
		 * Add animation to the toggle functionality.
		 *
		 * @param id
		 * @param visible
		 */
		Nette.toggle = function (id, visible) {
			var el = $('#' + id);
			if (visible) {
				el.slideDown();
			} else {
				el.slideUp();
			}
		};
	}
}

let instance = new NetteService();
module.exports = instance;
