'use strict';

const algoliaSearch = require('algoliasearch');
const aa = require('search-insights');

class SearchService {
	init() {
		const self = this;

		$(function () {
			if (!document.getElementById('web-search-input')) {
				return;
			}

			const searchInput = document.getElementById('web-search-input');
			const searchSuggestion = document.getElementById('web-search-suggest-box');
			const searchSuggestionList = document.getElementById('web-search-suggest-list');

			const appId = 'H4LQGBOSFX';
			const apiKey = 'c7b3622505fd847db5351cc4d007e64a';
			const indexName = 'amati';

			const search = algoliaSearch(appId, apiKey);
			const index = search.initIndex(indexName); // TODO replace amati for global index, but search by tenantID field

			let queryId = null;

			aa('init', {
				appId: appId,
				apiKey: apiKey,
				useCookie: true, // TODO set base on consent
			});

			let delayTimer = null;

			function searchItemClick(href, position, objectId) {
				aa('clickedObjectIDsAfterSearch', {
					eventName: 'searchClick',
					index: indexName,
					queryID: queryId,
					objectIDs: [objectId],
					positions: [position],
				});

				window.location.href = href;
			}

			function createHitItem(hit, position) {
				//const startDate = new Date(hit.dateStartTimestamp * 1000);
				const href = `/en/auction/${hit.saleSlug}/${hit.lotNumber}`;

				let li = document.createElement('li');
				let a = document.createElement('a');
				a.href = '#'

				a.addEventListener(
					'click',
					() => searchItemClick(href, position, hit.objectID)
				)

				a.innerHTML = hit._highlightResult.title.value;

				li.appendChild(a);

				return li;
			}

			function searchResults(hits) {
				searchSuggestionList.innerHTML = '';
				let i = 1;
				hits.forEach((hit) => {
					searchSuggestionList.appendChild(createHitItem(hit, i));
					i++;
				})

			}

			function searchQuery() {
				queryId = null;
				clearTimeout(delayTimer);
				delayTimer = setTimeout(function() {
					if (searchInput.value.length < 3) {
						//searchSuggestionList.innerHTML = '';
						return;
					}

					searchSuggestion.style.display = 'block';

					index.search(searchInput.value, {
						hitsPerPage: 10,
						clickAnalytics: true,
					}).then(({hits, queryID}) => {
						queryId = queryID;
						searchResults(hits);
					});
				}, 200);
			}

			if (searchInput !== null) {
				searchInput.addEventListener("keyup", searchQuery);
			}
		});
	}
}

let instance = new SearchService();
module.exports = instance;
