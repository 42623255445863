<template>
	<div class="container-upload">
		<div class="dropbox" v-if="multiple || imageSource.length < 1">
			<input
				type="file"
				:multiple="!!multiple"
				:name="uploadFieldName"
				@change="filesChange($event.target.name, $event.target.files)"
				:accept="normalizedMime"
				class="input-file"
			/>
			<p>
				Drag here or <span>Browse</span> to upload
			</p>
		</div>
		<div
				class="upload-img"
				v-for="(img, index) of imageSource"
				:key="index"
		>
			<img :src="img.src"/>
			<i
					class="fas fa-times"
					@click="splice(index)"
			></i>
			<p>{{ img.progress }}%</p>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;

export default defineComponent({
	props: {
		type: Number,
		section: Number,
		multiple: Boolean,
		mime: {
			type: String,
			default: "*",
		},
	},
	data() {
		const imageSource: Array<{
			id: string;
			src: string;
			progress: number;
			size: number;
		}> = [];
		return {
			imageSource,
			uploadFieldName: "photos",
			pendingRequests: 0,
			api: axios.create({}),
		};
	},
	computed: {
		isUploading(): boolean {
			return (
				!!this.imageSource.find((img) => img.progress !== 100) ||
				this.pendingRequests !== 0
			);
		},
		normalizedMime(): string {
			if (this.mime === "image") return "image/jpeg,image/png,image/gif,image/webp";
			return this.mime;
		},
	},
	methods: {
		reset() {
			// reset form to initial state
			this.imageSource = [];

			this.api.get("/web-api/v1/web/upload/get-files", {
				params: {
					type: this.type,
					fileSection: this.section,
				},
			}).then((response: { data: Array<{ thumb: string }> }) => {
				for (const [id, image] of Object.entries(response.data)) {
					this.imageSource.push({
						id,
						src: image.thumb,
						progress: 100,
						size: 100,
					});
				}
			});
		},
		splice(index: number) {
			this.api.delete("/web-api/v1/web/upload/", {params: {id: this.imageSource[index].id}});
			this.imageSource.splice(index, 1);
		},
		uploadImageToBucket(id: string, url: string, src: File) {
			const onUploadProgress = (progressEvent: ProgressEvent) => {
				if (progressEvent.lengthComputable) {
					let loadedPercent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
					const image = this.imageSource.find((img) => img.id === id);
					if (!!image) image.progress = loadedPercent;
				}
			}
			src.arrayBuffer().then(arrayBuffer => {
				this.api.put(url, arrayBuffer, {headers: {'Content-Type': src.type}, onUploadProgress}).then(() => {
					this.api.post("/web-api/v1/web/upload/save", {}, {params: {id: id}});
				});

			});
		},
		handleSingleImage(image: File) {
			if (!this.isFileImage(image)) {
				alert(`Please note that the file named '${image.name}' has an unsupported image type (*.jpg, *.png, *.gif, *.webp) and cannot be uploaded.`)
				return;
			}

			this.api.get("/web-api/v1/web/upload/get-url", {
				params: {
					type: this.type,
					section: this.section,
					mime: image.type,
					name: image.name
				}
			}).then((response) => {
				const id = response.data.data.id;
				const url = response.data.data.endpoint;
				const reader = new FileReader();
				reader.onload = (e) => {
					const dataURI = e.target?.result;
					if (dataURI) {
						// Add image to our list of images
						this.imageSource.push({
							id,
							src: dataURI.toString(),
							progress: 0,
							size: image.size,
						});
						this.uploadImageToBucket(id, url, image);
					}
				};
				reader.readAsDataURL(image);
			});
		},
		isFileImage(file: File) {
			return file.type.split('/')[0] === 'image';
		},
		filesChange(fieldName: string, fileList: FileList) {
			for (let index = 0, len = fileList.length; index < len; ++index) {
				const file = fileList.item(index);
				if (!!file) {
					this.handleSingleImage(file);
				}
			}

		},
	},
	watch: {
		isUploading(isUploading: boolean) {
			this.$emit("uploading", isUploading);
		},
	},
	mounted() {
		this.reset();

		/**
		 * Axios Request Interceptor
		 */
		this.api.interceptors.request.use((config) => {
			return new Promise((resolve, reject) => {
				let interval = setInterval(() => {
					if (this.pendingRequests < MAX_REQUESTS_COUNT) {
						this.pendingRequests++
						clearInterval(interval)
						resolve(config)
					}
				}, INTERVAL_MS)
			})
		})

		/**
		 * Axios Response Interceptor
		 */
		this.api.interceptors.response.use((response) => {
			this.pendingRequests = Math.max(0, this.pendingRequests - 1)
			return Promise.resolve(response)
		}, (error) => {
			this.pendingRequests = Math.max(0, this.pendingRequests - 1)
			return Promise.reject(error)
		})

	},
	emits: ["uploading"],
});
</script>

<!-- SASS styling -->
<style lang="scss">
.container-upload {
	.upload-img {
		display: inline-block;
		position: relative;
		height: 70px;
		width: 70px;
		margin-right: 5px;
		margin-bottom: 5px;

		i {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: white;
			height: 14px;
			width: 15px;

			cursor: pointer;
			z-index: 1;
		}
	}

	img {
		height: 70px;
		width: 70px;
	}

	.dropbox {
		position: relative;
		display: inline-block;
		width: 100%;
		min-height: 326px;
		margin-top: 0;
		margin-bottom: 1rem;
		color: transparent;
		font-size: inherit;
		vertical-align: middle;
		background: #fff;
		border: 1px dashed #d7d7d7;
		border-radius: 5px;
		outline: none;
		cursor: pointer;
		transition: border 500ms ease-out;

		p {
			position: absolute;
			top: 188px;
			right: 0;
			left: 0;
			font-weight: 300;
			font-size: 24px;
			line-height: 34px;
			text-align: center;
			color: #818181;

			span {
				color: #39aad0;
				cursor: pointer;
			}

			@media (max-width: 1500px) {
				font-size: 18px;
				line-height: 26px;
			}

			@media only screen and (min-width: 768px) {
				top: 210px;
			}

			@media only screen and (max-width: 438px) {
				top: 180px;
			}
		}

		&:before {
			position: absolute;
			top: 60px;
			right: 0;
			left: 0;
			width: 127px;
			height: 124px;
			margin: auto;
			opacity: 0.3;
			transition: opacity 500ms ease-out;
			content: url("/cdn/admin/images/upload-images.svg");

			@media (max-width: 768px) {
				top: 50px;
				width: 107px;
				height: 104px;
			}
		}

		&:hover {
			border: 1px solid #818181;
		}

		&:hover:before {
			opacity: 1;
		}

		@media (max-width: 768px) {
			min-height: 245px;
		}

		.input-file {
			opacity: 0; /* invisible but it's there! */
			width: 100%;
			height: 100%;
			position: absolute;
			cursor: pointer;
		}
	}
}
</style>
