class Menu {
	init() {
		$('#nav__bars-toogle').on('click', function () {
			$(".nav__burger-items").toggleClass("nav__burger-items--show");
			$(".burger-items").toggleClass("burger-items--show");
			// bellmansSpecific
			$(".bellmans-main-mobile-menu").toggleClass("bellmans-mobile-menu--none");

			if ($(".nav__burger-items").hasClass("nav__burger-items--show")) {
				$('.fa-bars').hide();
				$('.fa-times').show();
			} else {
				$('.fa-bars').show();
				$('.fa-times').hide();
			}

			if ($(".burger-items").hasClass("burger-items--show")) {
				$('.fa-bars').hide();
				$('.fa-times').show();
			} else {
				$('.fa-bars').show();
				$('.fa-times').hide();
			}
		});
		$('.burger__item--language').on('click', function () {
			$(".panel-languages").toggleClass("rotate-arrow");
		})

		$('#times-toogle-button').on('click', function () {
			$(".burger-items").toggleClass("burger-items--show");
			// bellmansSpecific
			$(".bellmans-main-mobile-menu").toggleClass("bellmans-mobile-menu--none");

			if ($(".burger-items").hasClass("burger-items--show")) {
				$('.fa-bars').hide();
				$('.fa-times').show();
			} else {
				$('.fa-bars').show();
				$('.fa-times').hide();
			}
		});

		// bellmansSpecific
		$('.bellmans-mobile-menu .nav-item').on('click', function () {
			$(this).find('.dropdown-menu').toggleClass("bellmans-mobile-submemenu");
			$(this).find('.fa-angle-down').toggleClass("rotate-arrow");
		});
	}
}


module.exports = new Menu();
