import { defineComponent } from "vue";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, doc, onSnapshot, } from "firebase/firestore";
import { environment } from "../config/env_real-time";
var app = initializeApp(environment.firebaseConfig);
var db = getFirestore(app, environment.firestore.databaseId);
if (!environment.production) {
    connectFirestoreEmulator(db, "localhost", 8080);
}
export default defineComponent({
    props: {
        partnerTicker: {
            type: String,
            required: true,
        },
        auctionShortName: {
            type: String,
            required: true,
        },
        lotNumber: {
            type: String,
            required: true,
        },
        currentValue: {
            type: Number,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
        startingBidStr: {
            type: String,
            required: true,
        },
        currentBidStr: {
            type: String,
            required: true,
        },
        hasBid: {
            type: String,
            required: true,
        },
        updateLink: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            lotData: {
                winningBid: {
                    amount: this.$props.currentValue,
                    currency: this.$props.currency,
                },
            },
            unsubscribe: null,
            isCurrentBid: this.$props.hasBid === 'true' || false,
        };
    },
    watch: {
        partnerTicker: "fetchRealTimeData",
        auctionShortName: "fetchRealTimeData",
        lotNumber: "fetchRealTimeData",
        currentValue: "fetchRealTimeData",
    },
    methods: {
        fetchRealTimeData: function () {
            var _this = this;
            if (this.unsubscribe) {
                this.unsubscribe();
            }
            var path = "partners/" + this.partnerTicker.toLowerCase() + "/auctions/" + this.auctionShortName.toLowerCase() + "/lots/" + this.lotNumber.toLowerCase();
            var docRef = doc(db, path);
            this.unsubscribe = onSnapshot(docRef, function (doc) {
                if (doc.exists()) {
                    _this.lotData = doc.data();
                    _this.isCurrentBid = true;
                    _this.updateBidAndTimeAJAX();
                }
            });
        },
        formatCurrency: function (value, currency) {
            var formatter = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: currency,
                minimumFractionDigits: 0,
            });
            return formatter.format(value);
        },
        updateBidAndTimeAJAX: function () {
            // @ts-ignore
            if ($.active === 0) {
                // @ts-ignore
                $.nette.ajax({
                    type: "GET",
                    url: this.updateLink,
                });
            }
        },
    },
    mounted: function () {
        this.fetchRealTimeData();
    },
    beforeUnmount: function () {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
});
