'use strict';

class Notifications {
	init() {
		$(function () {
			const COOKIE_STRING = 'hide';

			// Search for cookie only alerts
			$('[data-alert-cookie]').each(function () {
				var $this = $(this);
				var cookieName = $this.data('alert-cookie');

				if (Cookies.get(cookieName) !== COOKIE_STRING) {
					$this.show();
				}
			});

			// Close button
			$('[data-hide-alert]').click(function () {
				var $flash = $(this).closest('[data-alert]');
				if ($flash.parent('header').length > 0) {
					$flash.hide("slide", {direction: "up"}, "fast");
				} else {
					$flash.hide("fade");
				}

				//Write a cookie if needed
				if ($flash.data('alert-cookie') !== 'undefined') {
					var date;
					if ($flash.data('expire')) {
						date = new Date($flash.data('expire')); // on specified date and time
					} else {
						date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); //in 24 hours
					}

					Cookies.set($flash.data('alert-cookie'), COOKIE_STRING, {expires: date});
				}
			});
		});
	}
}

let instance = new Notifications();
module.exports = instance;
