'use strict';

class GetFileName {
	init() {
		$(document).ready(function () {
			$('.file-name').change(function (e) {
				let fileName = e.target.files[0].name;
				$(this).closest('div').find("span").text(fileName);
			});
		});
	}
}

module.exports = new GetFileName();
