'use strict';

class Cookies {
	init() {
		$(function () {
			$('.eu-cookies .button').click(function () {
				window.Cookies.set('eu-cookies', '1', {expires: 365});
				$('.eu-cookies').hide();
			});
		});
	}
}

let instance = new Cookies();
module.exports = instance;
