'use strict'
const config = require('../config');

class Websocket {
	init() {
		return new Promise(((resolve, reject) => {
			if (!('WebSocket' in window)) {
				reject('WebSocket not supported');
				return;
			}

			const ws = new WebSocket(config.websocketURL);
			ws.onopen = function () {
				resolve(ws);
			};

			ws.onclose = function () {
				reject('Connection closed');
			};
		}))
	}
}

module.exports = new Websocket();
