'use strict';

import fancybox from '@fancyapps/fancybox';

class Popups {
	init() {

		$().fancybox({
			selector: '[data-fancybox="thumbs"]',
			protect: true,
			buttons: [
				"zoom",
				"fullScreen",
				"close"
			],
		});

		$().fancybox({
			selector: '[data-fancybox="fitImage"]',
			protect: true,
			smallBtn: false,
			defaultType: "image",
			buttons: [
				"zoom",
				"fullScreen",
				"close"
			],

			afterShow: function (instance, current) {
				instance.scaleToFit();
			},

			animationEffect: "zoom",
		});

		$().fancybox({
			selector: '[data-fancybox="fullImage"]',
			protect: true,
			buttons: [
				"zoom",
				"fullScreen",
				"close"
			],
			fitToView: false,
			afterShow: function (instance) {
				instance.scaleToActual();
			},
			touch: true,
		});

		$().fancybox({
			selector: '[data-fancybox="info"]',
			protect: true,
			touch: true,
		});

		$(function () {
			$('[data-trigger="fancybox-thumbs-gallery"]').click(function (e) {
				e.preventDefault();
				let imgUrl = $(this).attr('href');
				let targetImage = $(`#lot_gallery a[data-fancybox][href="${imgUrl}"]`);
				if (targetImage.length) {
					targetImage.trigger('click');
				}
			});
		});
	}
}

let popups = new Popups();
module.exports = popups;
