export default function (form) {
	if (form.length > 0) {
		const title = form.find("input[name=title]");
		const firstName = form.find("input[name=firstname]");
		const lastName = form.find("input[name=lastname]");
		const salutation = form.find("input[name=salutation]");
		let isSalutationModified = !(
			salutation.val() === "" ||
			salutation.val() === title.val() + " " + lastName.val() ||
			salutation.val() === firstName.val() + " " + lastName.val()
		);
		const recalculateSalutation = () => {
			if (isSalutationModified === false) {
				if (title.val() !== "") {
					salutation.val(title.val() + " " + lastName.val());
				} else {
					salutation.val(firstName.val() + " " + lastName.val());
				}
			}
		};
		title.change(recalculateSalutation);
		firstName.change(recalculateSalutation);
		lastName.change(recalculateSalutation);
		salutation.change(() => (isSalutationModified = true));
	}
}
