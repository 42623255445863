'use strict';

class Discover {
	init() {

		$(function () {
			$(window).scroll(function () {
				if ($(this).scrollTop() < 1) {
					$('#discover-more').fadeIn(300);
				} else {
					$('#discover-more').fadeOut(300);
				}


				if ($(this).scrollTop() > 400) {
					$('.scroll').fadeIn(300);
				} else {
					$('.scroll').fadeOut(300);
				}

			});

			$("#discover-more").click(function () {
				$('html, body').animate({
					scrollTop: $("section").eq(1).offset().top - 70
				}, 1000);
			});
		});

	}
}

let instance = new Discover();
module.exports = instance;
