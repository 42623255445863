<template>
	<div class="range-slider" :style="'--currency:\'' + currency + '\''">
		<MultiRangeSlider
			baseClassName="multi-range-slider"
			:min="minValue"
			:max="maxValue"
			:ruler="false"
			:label="true"
			:range-margin="0"
			:minValue="selectedMin"
			:maxValue="selectedMax"
			@input="updateSelected"
		/>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import MultiRangeSlider from "multi-range-slider-vue";

export default defineComponent({
	components: {
		MultiRangeSlider,
	},
	props: {
		minValue: Number,
		maxValue: Number,
		min: Number,
		max: Number,
		currency: { type: String, default: "£" },
	},
	data() {
		return {
			selectedMin: 0,
			selectedMax: 0,
		};
	},
	created() {
		this.selectedMin = this.min;
		this.selectedMax = this.max;
	},
	methods: {
		updateSelected(e) {
			this.selectedMin = document.getElementById("min-est").value =
				e.minValue;
			this.selectedMax = document.getElementById("max-est").value =
				e.maxValue;
		},
	},
});
</script>

<style lang="scss" scoped>
.range-slider {
	:deep(.multi-range-slider) {
		box-shadow: none !important;
		border: 0 !important;
		border-radius: 0 !important;

		.bar-left,
		.bar-right {
			box-shadow: none !important;
			border-radius: 0 !important;
			background: #fff;
			border: 1px solid #ddd;
		}

		.bar-inner {
			background: #333;
		}

		.label {
			&:before {
				display: inline;
				content: var(--currency);
			}
		}

		.thumb {
			&:before {
				box-shadow: none !important;
			}

			&.thumb-left:before {
				left: 11px;
			}

			&.thumb-right:before {
				left: -11px;
			}

			.caption * {
				background: #555;

				&:before {
					display: inline;
					content: var(--currency);
				}
			}
		}
	}
}
</style>
