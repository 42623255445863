'use strict';

require('ez-plus');

class Zoom {
	init() {
		$('#bowFront').ezPlus({
			constrainType: 'height',
			constrainSize: 500,
			containLensZoom: false,
			gallery: 'gallery_01',
			cursor: 'pointer',
			galleryActiveClass: 'active',
			zoomWindowPosition: 6,
			zoomWindowHeight: 150,
			zoomWindowWidth: 600,
			zoomWindowFadeIn: 200,
			zoomWindowFadeOut: 200,
			lensFadeIn: 200,
			lensFadeOut: 200,
			scrollZoom: true
		});

		$('#bowBack').ezPlus({
			constrainType: 'height',
			constrainSize: 500,
			containLensZoom: false,
			gallery: 'gallery_01',
			cursor: 'pointer',
			galleryActiveClass: 'active',
			zoomWindowPosition: 6,
			zoomWindowHeight: 150,
			zoomWindowWidth: 600,
			zoomWindowFadeIn: 200,
			zoomWindowFadeOut: 200,
			lensFadeIn: 200,
			lensFadeOut: 200,
			scrollZoom: true
		});
	}
}

module.exports = new Zoom();
