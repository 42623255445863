import { defineComponent } from "vue";
import * as moment from "moment-timezone";
export default defineComponent({
    props: {
        saleId: String,
        lotNumber: String,
        tenantId: String,
        deadlineTimestamp: Number,
        timeZone: String,
        dateFormat: String,
    },
    data: function () {
        return {
            endDate: moment.utc(),
        };
    },
    methods: {
        uuidv4: function () {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        subscribeToWs: function () {
            this.$socket.sendObj({
                requestId: this.uuidv4(),
                method: "bidListener",
                data: {
                    lotNumber: this.lotNumber,
                    saleId: this.saleId,
                    tenant: this.tenantId,
                },
            });
        },
        formatDate: function (date) {
            return moment.utc(date).tz(this.timeZone || 'UTC').format(this.dateFormat);
        },
        setEndDate: function (timestamp) {
            this.endDate = moment.unix(timestamp).utc();
        },
    },
    mounted: function () {
        var _this = this;
        this.$options.sockets.onopen = function () { return _this.subscribeToWs(); };
        this.$options.sockets.onmessage = function (websocketMessage) {
            var message = JSON.parse(websocketMessage.data);
            if (message.method === "newProductBid" &&
                message.lotNumber === _this.lotNumber &&
                message.saleId === _this.saleId &&
                message.data.endTimeTimestamp) {
                _this.setEndDate(message.data.endTimeTimestamp);
            }
        };
        this.setEndDate(this.deadlineTimestamp || 0);
    },
});
