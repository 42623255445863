import { defineComponent } from "vue";
import axios from "axios";
var MAX_REQUESTS_COUNT = 1;
var INTERVAL_MS = 10;
export default defineComponent({
    props: {
        type: Number,
        section: Number,
        multiple: Boolean,
        mime: {
            type: String,
            default: "*",
        },
    },
    data: function () {
        var imageSource = [];
        return {
            imageSource: imageSource,
            uploadFieldName: "photos",
            pendingRequests: 0,
            api: axios.create({}),
        };
    },
    computed: {
        isUploading: function () {
            return (!!this.imageSource.find(function (img) { return img.progress !== 100; }) ||
                this.pendingRequests !== 0);
        },
        normalizedMime: function () {
            if (this.mime === "image")
                return "image/jpeg,image/png,image/gif,image/webp";
            return this.mime;
        },
    },
    methods: {
        reset: function () {
            var _this = this;
            // reset form to initial state
            this.imageSource = [];
            this.api.get("/web-api/v1/web/upload/get-files", {
                params: {
                    type: this.type,
                    fileSection: this.section,
                },
            }).then(function (response) {
                for (var _i = 0, _a = Object.entries(response.data); _i < _a.length; _i++) {
                    var _b = _a[_i], id = _b[0], image = _b[1];
                    _this.imageSource.push({
                        id: id,
                        src: image.thumb,
                        progress: 100,
                        size: 100,
                    });
                }
            });
        },
        splice: function (index) {
            this.api.delete("/web-api/v1/web/upload/", { params: { id: this.imageSource[index].id } });
            this.imageSource.splice(index, 1);
        },
        uploadImageToBucket: function (id, url, src) {
            var _this = this;
            var onUploadProgress = function (progressEvent) {
                if (progressEvent.lengthComputable) {
                    var loadedPercent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    var image = _this.imageSource.find(function (img) { return img.id === id; });
                    if (!!image)
                        image.progress = loadedPercent;
                }
            };
            src.arrayBuffer().then(function (arrayBuffer) {
                _this.api.put(url, arrayBuffer, { headers: { 'Content-Type': src.type }, onUploadProgress: onUploadProgress }).then(function () {
                    _this.api.post("/web-api/v1/web/upload/save", {}, { params: { id: id } });
                });
            });
        },
        handleSingleImage: function (image) {
            var _this = this;
            if (!this.isFileImage(image)) {
                alert("Please note that the file named '" + image.name + "' has an unsupported image type (*.jpg, *.png, *.gif, *.webp) and cannot be uploaded.");
                return;
            }
            this.api.get("/web-api/v1/web/upload/get-url", {
                params: {
                    type: this.type,
                    section: this.section,
                    mime: image.type,
                    name: image.name
                }
            }).then(function (response) {
                var id = response.data.data.id;
                var url = response.data.data.endpoint;
                var reader = new FileReader();
                reader.onload = function (e) {
                    var _a;
                    var dataURI = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result;
                    if (dataURI) {
                        // Add image to our list of images
                        _this.imageSource.push({
                            id: id,
                            src: dataURI.toString(),
                            progress: 0,
                            size: image.size,
                        });
                        _this.uploadImageToBucket(id, url, image);
                    }
                };
                reader.readAsDataURL(image);
            });
        },
        isFileImage: function (file) {
            return file.type.split('/')[0] === 'image';
        },
        filesChange: function (fieldName, fileList) {
            for (var index = 0, len = fileList.length; index < len; ++index) {
                var file = fileList.item(index);
                if (!!file) {
                    this.handleSingleImage(file);
                }
            }
        },
    },
    watch: {
        isUploading: function (isUploading) {
            this.$emit("uploading", isUploading);
        },
    },
    mounted: function () {
        var _this = this;
        this.reset();
        /**
         * Axios Request Interceptor
         */
        this.api.interceptors.request.use(function (config) {
            return new Promise(function (resolve, reject) {
                var interval = setInterval(function () {
                    if (_this.pendingRequests < MAX_REQUESTS_COUNT) {
                        _this.pendingRequests++;
                        clearInterval(interval);
                        resolve(config);
                    }
                }, INTERVAL_MS);
            });
        });
        /**
         * Axios Response Interceptor
         */
        this.api.interceptors.response.use(function (response) {
            _this.pendingRequests = Math.max(0, _this.pendingRequests - 1);
            return Promise.resolve(response);
        }, function (error) {
            _this.pendingRequests = Math.max(0, _this.pendingRequests - 1);
            return Promise.reject(error);
        });
    },
    emits: ["uploading"],
});
