import { defineComponent } from "vue";
import * as moment from "moment";
export default defineComponent({
    props: {
        saleId: String,
        lotNumber: String,
        tenantId: String,
        lang: String,
        secondsFromEnd: Number,
        shouldRefreshAtTheEnd: Boolean,
    },
    data: function () {
        return {
            langParsed: {
                days: "days",
                hours: "hrs",
                minutes: "mins",
                seconds: "seconds",
                ended: "Bidding ended",
            },
            endsIn: moment.duration(),
            endDate: moment.utc(),
            alreadyRefreshed: false,
        };
    },
    methods: {
        uuidv4: function () {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        subscribeToWs: function () {
            this.$socket.sendObj({
                requestId: this.uuidv4(),
                method: "bidListener",
                data: {
                    lotNumber: this.lotNumber,
                    saleId: this.saleId,
                    tenant: this.tenantId,
                },
            });
        },
        updateDiff: function () {
            var _a, _b;
            this.endsIn = moment.duration(this.endDate.diff(moment().utc()));
            if (this.endsIn.asMilliseconds() <= 0 &&
                this.shouldRefreshAtTheEnd &&
                !this.alreadyRefreshed) {
                this.alreadyRefreshed = true;
                window.location.reload();
            }
            else if (this.endsIn.asMilliseconds() <= 0) {
                (_a = document
                    .querySelector(".catalogue-lot[data-lot-number='" +
                    this.lotNumber +
                    "']")) === null || _a === void 0 ? void 0 : _a.remove();
                (_b = document
                    .querySelector(".catalogue-list tr[data-lot-number='" +
                    this.lotNumber +
                    "']")) === null || _b === void 0 ? void 0 : _b.remove();
            }
            window.requestAnimationFrame(this.updateDiff);
        },
        setEndDate: function (secondsFromEnd) {
            this.endDate = moment.utc().add(secondsFromEnd, "seconds");
        },
    },
    mounted: function () {
        var _this = this;
        this.langParsed = JSON.parse(this.lang || "");
        this.$options.sockets.onopen = function () { return _this.subscribeToWs(); };
        this.$options.sockets.onmessage = function (websocketMessage) {
            var message = JSON.parse(websocketMessage.data);
            if (message.method === "newProductBid" &&
                message.lotNumber === _this.lotNumber &&
                message.saleId === _this.saleId) {
                var secondsToEnd = message.data.secondsFromEnd;
                _this.setEndDate(secondsToEnd || 0);
            }
        };
        this.setEndDate(this.secondsFromEnd || 0);
        window.requestAnimationFrame(this.updateDiff);
    },
});
