'use strict'
const config = require('../config');

class DateTimeZoneProvider {
	constructor(config) {
		 this.config = config;
	}

	init() {
		if (this.config.dtzpIsInitialized === null) {
			return;
		}

		const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
		const providerIsInitialized = this.config.dtzpIsInitialized === true;
		const providerTimezone = this.config.dtzpTimezone;

		if (!providerIsInitialized || (providerIsInitialized && browserTimezone !== providerTimezone)) {
			$.ajax({
				url: "/web-api/v1/web/browser-date-time/zone",
				method: "POST",
				data: {
					timezone: browserTimezone
				},
				success: (response) => {
					if (response.status === "ok" && response.state === "changed") {
						location.reload();
					}
				}
			});
		}
	}
}

module.exports = new DateTimeZoneProvider(config);
