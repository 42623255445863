export const environment = {
	firebaseConfig: {
		apiKey: "AIzaSyDmHNedimbrX7ksgwlaXRo0xUcog62dSuY",
		authDomain: "snoofa-real-time-prod.firebaseapp.com",
		projectId: "snoofa-real-time-prod",
		storageBucket: "snoofa-real-time-prod.appspot.com",
		messagingSenderId: "496368865509",
		appId: "1:496368865509:web:d5d6c5f7364766c2bbc40c",
		measurementId: "G-0M62EL9V1H"
	},

	production: true,

	firestore: {
		databaseId: '(default)'
	}
};
