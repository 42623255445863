'use strict'
const wsLib = require('./websocket.module');

class BidUpdate {
	constructor() {
		this._subscriptions = {};
		this.onUpdate = undefined;  // custom callback, id as argument
		this.onClose = undefined;
		this.onOpen = undefined;
		this.onFocus = undefined;
		this.onLiveOfferedLotChanged = undefined;
	}

	init(bidContainersSelectors, configs) {
		this.bidContainersSelectors = bidContainersSelectors;
		this.connect(configs);
		window.onfocus = () => {
			this.onFocus && this.onFocus();
		}
	}

	connect(configs) {
		const that = this;
		wsLib.init()
			.then(ws => {
				this.onOpen && this.onOpen();
				this.bidWs = ws;
				configs.forEach(this.startListening.bind(this));
				this.bidWs.onmessage = this.changeBid.bind(this);
				this.bidWs.onclose = function () {
					that.connect(configs);
				}
			})
			.catch(() => {
				this.onClose && this.onClose();
				setTimeout(this.connect.bind(this, configs), 5000);
			});
	}

	send(msg) {
		this.bidWs.send(JSON.stringify(msg));
	}

	startListening({tenant, lotNumber, saleId}) {
		this.send({
			'requestId': `${lotNumber}`,
			'method': 'bidListener',
			'data': {
				'tenant': tenant,
				'saleId': saleId,
				'lotNumber': lotNumber,
			},
		});
	}

	stopListening(id) {
		if (!(id in this._subscriptions)) {
			return;
		}
		this.send({
			'requestId': `${id}`,
			'method': 'bidUnsubscribe',
			'data': {
				'subscriptionId': data._subscriptions[id],
			},
		});
		delete this._subscriptions[id];
	}

	changeBid(env) {
		if (env.data === 'hello') {
			return;
		}
		let data;
		try {
			data = JSON.parse(env.data);
		} catch (e) {
			console.error('ws: Invalid Data:', env.data);
			return;
		}
		if (data && data.response && data.response.subscriptionId !== undefined) {
			this._subscriptions[data.requestId] = data.response.subscriptionId;
			return;
		}
		if (data && data.method && data.method === 'lotOfferedLive') {
			this.onLiveOfferedLotChanged && this.onLiveOfferedLotChanged();
			return;
		}
		if (data && data.method && data.method === 'lotClosedLive') {
			this.onLiveOfferedLotChanged && this.onLiveOfferedLotChanged();
			return;
		}
		if (data && data.method && data.method === 'newProductBid' && data.data) {
			if (!data || !data.data || typeof data.data.newValue !== 'number' || typeof data.data.valueLabel !== 'string') {
				console.error('ws: Invalid Data:', data);
				return;
			}

			this.renderNewBid(data.saleId, data.lotNumber, data.data.newValue, data.data.valueLabel);
		}
	}

	renderNewBid(saleId, lotNumber, newValue, valueLabel) {
		const that = this;
		this.bidContainersSelectors.forEach(function (selectorTemplate, selectorIndex) {
			const selector = selectorTemplate.replace('%id', lotNumber).replace('%saleId', saleId);
			const template = that.templates[selectorIndex].replace('%bidValue', valueLabel);
			$(selector).html(template);
		});

		this.onUpdate && this.onUpdate(lotNumber, saleId);
	}
}

module.exports = new BidUpdate();
