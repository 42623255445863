'use strict';
import Chart from 'chart.js';

class Charts {
	init() {
		let $priceChart = $("#priceChart");
		if ($priceChart.length) {
			let ctx = $priceChart.get(0).getContext('2d');
			let datasets = $priceChart.data('datasets');
			let title = $priceChart.data('title');
			let priceChart = new Chart(ctx, {
				type: 'line',
				data: {
					datasets: datasets,
				},
				options: {
					title: {
						display: true,
						text: title,
					},
					scales: {
						xAxes: [{
							type: 'time',
							distribution: 'series'
						}],
					}
				}
			});
		}

	}
}

module.exports = new Charts();
